
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'
import { Allign } from '@/modules/system/types'

@Component
export default class BaseCard extends Vue {
  @Prop(String)
  readonly caption: string | undefined;

  @Prop({ default: false })
  readonly darkTitle!: boolean | string;

  @Prop({ default: false })
  readonly fluid!: boolean | string;

  @Prop({ default: false })
  readonly hideTitle!: boolean | string;

  @Prop({ default: false })
  readonly hideHeader!: boolean | string;

  @Prop({ default: false })
  readonly hideTitleDivider!: boolean | string;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop([String, Number])
  readonly maxWidth: string | number | undefined;

  @Prop({ default: false })
  readonly flat!: boolean;

  @Prop({ default: 'center' })
  readonly titleAlign!: Allign;

  @Prop(String)
  readonly color: string | undefined;

  @Prop(String)
  readonly titleColor: string | undefined;

  @Prop([String, Number])
  readonly height: string | number | undefined;

  common = $app.module(Common);
  system = $app.module(System);
  fab = false;

  get cardStyle(): any {
    if (this.height === undefined || this.height === 'grow') {
      return {
        'width': '100%'
      };
    } else if (this.height === 'flex' || this.height === 'fill-height') {
      return {
        'width': '100%',
        'display': 'flex',
        'flex-flow': 'column',
        'height': '100%'
      };
    } else {
      return {
        'width': '100%'
      };
    }
  }

  get contentStyle(): any {
    if (this.height === undefined || this.height === 'grow') {
      return {
      };
    } else if (this.height === 'fill-height') {
      return {
        'flex': '1 1 0',
        'overflow-y': 'visible',
        'overflow-x': 'visible'
      };
    } else if (this.height === 'flex') {
      return {
        'flex': '1 1 0',
        'overflow-y': 'auto',
        'overflow-x': 'hidden'
      };
    } else {
      return {
        'height': this.height,
        'overflow-y': 'auto',
        'overflow-x': 'hidden'
      };
    }
  }

  get fixStyle(): any {
    if (this.height === undefined || this.height === 'grow') {
      return {
      };
    } else if (this.height === 'flex') {
      return {
        'flex': '0 1 auto'
      };
    } else {
      return {
      };
    }
  }

  onScroll(e: any) {
    this.$emit('scroll', e);
  }
}
